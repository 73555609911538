
import {defineComponent} from "vue";
import {IonCol, IonContent, IonGrid, IonPage, IonRow, IonImg, IonIcon} from "@ionic/vue";
import {mapState} from "vuex";
import {checkmarkCircle} from "ionicons/icons";

export default defineComponent({
    name: 'DesignSelect',
    components: {IonPage, IonContent, IonGrid, IonRow, IonCol, IonImg, IonIcon},
    computed: {
        ...mapState(['invitationDesigns', 'selectedInvitationDesign'])
    },
    setup(){
        return {
            checkmarkCircle
        }
    },
    methods: {
        selectInvitationDesign(invite: any){
            this.$store.dispatch('selectInvitationDesign', invite.id)
            this.$router.push('invitation')
        }
    }
})
